html {
	body {
		section {
			height: 100vh;
			width: 100%;
			box-shadow: 0px 0px 0px 5px var(--comment) inset;
		}

		section#home {
			box-shadow: none;
			background-image: url("./../assets/welcome-background.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;

			animation: fadeIn 5s;
			-webkit-animation: fadeIn 5s;
			-moz-animation: fadeIn 5s;
			-o-animation: fadeIn 5s;
			-ms-animation: fadeIn 5s;

			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently
							  supported by Chrome, Edge, Opera and Firefox */

			h1 {
				color: var(--text-color);
				font-size: 8rem;
				margin: 0;
				text-shadow: 3px 3px var(--red), 5px 5px var(--orange), 8px 8px var(--green), 11px 11px var(--purple),
					14px 14px var(--background-color);
			}
		}

		section#welcome {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.welcome-wrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 3rem;

				.profile-img {
					height: 360px;
					width: 360px;
					background-image: url("./../assets/profile_photo.jpg");
					background-repeat: no-repeat;
					background-size: cover;

					border-radius: 40%;

					filter: grayscale(50%);

					box-shadow: inset 0 1px 150px 10px var(--black), 0px 0px 30px 0px var(--black);
					// -webkit-box-shadow: inset 10px 10px 50px var(--black);
					// -moz-box-shadow: inset 10px 10px 50px var(--black);

					&:hover {
						background-image: url("./../assets/goofy_profile_photo.jpg");
					}
				}

				&:has(.profile-img:hover) {
					.introduction {
						.easter-egg {
							visibility: visible;
						}
					}
				}

				.introduction {
					.easter-egg {
						visibility: hidden;
					}
					h1 {
						margin: 0;
						line-height: 2.7rem;
					}

					h1:nth-child(1) {
						padding-left: 0;
					}
					h1:nth-child(2) {
						padding-left: 2rem;
					}
					h1:nth-child(3) {
						padding-left: 4rem;
					}
					h1:nth-child(4) {
						padding-left: 6rem;
					}
					h1:nth-child(5) {
						padding-left: 8rem;
					}
				}
			}
		}

		section#projects {
			.projects-wrapper {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				height: 100%;
				width: 100%;

				.image {
					grid-column: 2;
					grid-row: 1;
					background-image: url("./../assets/projects-background.jpg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					z-index: -1;
					border-left: 5px solid var(--comment);
				}

				.content {
					grid-column: 1;
					grid-row: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 30px;

					.project-list {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 2rem;

						.project {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							text-decoration: none;
							width: 300px;
							height: 170px;
							border-radius: 30px;

							&.iris{
								background-image: url('./../assets/irisloebbering.png');
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
							}
							span {
								background-color: var(--selection-color-transparent);
								border-radius: 15px;
								padding: 10px;
								font-size: 2rem;
								font-weight: 900;
								color: var(--text-color);
							}
						}
					}
				}
			}
		}

		section#about {
			.about-wrapper {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				height: 100%;
				width: 100%;

				.image {
					grid-column: 1;
					background-image: url("./../assets/about-background.jpg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					z-index: -1;
					border-right: 5px solid var(--comment);
				}

				.content {
					grid-column: 2;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 30px;
				}
			}
		}

		section#contact {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.contact-wrapper {
				text-align: center;

				.social {
					padding-top: 40px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-evenly;
					gap: 15px;

					.item {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;
						gap: 15px;

						a {
							color: white;
							font-style: normal;
						}
					}
				}

				.note {
					padding-top: 40px;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	html {
		body {
			section {
				height: auto;
				box-shadow: none;
			}

			section#home,
			section#welcome,
			section#projects,
			section#contact {
				height: 100vh;
			}

			section#home {
				h1 {
					font-size: 5rem;
				}
			}

			section#welcome {
				flex-direction: column;

				.welcome-wrapper {
					flex-direction: column;

					.profile-img {
						width: 200px;
						height: 200px;
						box-shadow: inset 0 1px 50px 10px var(--black), 0 0 30px var(--black);
					}

					.introduction {
						h1:nth-child(n) {
							padding: 0;
						}
					}
				}
			}

			section#projects {
				.projects-wrapper {
					grid-template-columns: repeat(1, 1fr);

					.image {
						grid-column: 1;
						grid-row: 1;
						border: none;
						min-height: 240px;
					}

					.content {
						grid-column: 1;
						grid-row: 2;
						justify-content: flex-start;
					}
				}
			}

			section#about {
				.about-wrapper {
					grid-template-columns: repeat(1, 1fr);

					.image {
						grid-column: 1;
						grid-row: 1;
						border: none;
						min-height: 240px;
					}

					.content {
						grid-column: 1;
						grid-row: 2;
						justify-content: flex-start;
					}
				}
			}

			section#contact {
				.contact-wrapper {
					padding: 20px;
					.social {
						flex-wrap: wrap;
					}
				}
			}
		}
	}
}
