@import "https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap";
html body nav.navbar {
  color: var(--text-color);
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 10px;
}

html body nav.navbar .navbar__container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

html body nav.navbar .navbar__container a {
  color: var(--text-color);
  text-decoration: none;
}

html body nav.navbar .navbar__container .navbar__logo img {
  height: 50px;
  width: 50px;
}

html body nav.navbar .navbar__container .navbar__links {
  background-color: var(--selection-color);
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 0;
  font-weight: bold;
  display: flex;
}

html body nav.navbar .navbar__container .navbar__links .active {
  background-color: var(--comment);
  border-radius: 5px;
}

html body nav.navbar .navbar__container .navbar__links a {
  padding: 15px;
}

html body nav.navbar .navbar__container .navbar__links a:before {
  content: "";
  border-top: 3px solid #0000;
  transition: transform .25s ease-in-out;
  display: block;
  transform: scaleX(0);
}

html body nav.navbar .navbar__container .navbar__links a:after {
  content: "";
  border-bottom: solid 3px var(--green);
  transition: transform .25s ease-in-out;
  display: block;
  transform: scaleX(0);
}

html body nav.navbar .navbar__container .navbar__links a:hover:after {
  transform: scaleX(1);
}

html body nav.navbar .navbar__container .mobile__btn {
  display: none;
}

html body nav.navbar .navbar__container .mobile__btn svg {
  height: 25px;
  width: 25px;
}

@media (max-width: 768px) {
  html body nav.navbar {
    background-color: var(--selection-color);
    top: 0;
  }

  html body nav.navbar .navbar__container {
    justify-content: space-between;
    padding: 0 20px;
  }

  html body nav.navbar .navbar__container .navbar__logo {
    display: block;
  }

  html body nav.navbar .navbar__container .navbar__logo img {
    height: 30px;
    width: 30px;
    padding: 10px;
  }

  html body nav.navbar .navbar__container .navbar__links {
    display: none;
  }

  html body nav.navbar .navbar__container .navbar__links.mobile {
    width: 100%;
    height: 100vh;
    background-color: var(--background-color);
    border-radius: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    display: flex;
    position: absolute;
    top: 54px;
    left: 0;
  }

  html body nav.navbar .navbar__container .mobile__btn {
    height: 30px;
    width: 30px;
    display: block;
  }
}

html body section {
  height: 100vh;
  width: 100%;
  box-shadow: 0px 0px 0px 5px var(--comment) inset;
}

html body section#home {
  box-shadow: none;
  -ms-animation: fadeIn 5s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  background-image: url("welcome-background.f4011ecc.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  animation: fadeIn 5s;
  display: flex;
}

html body section#home h1 {
  color: var(--text-color);
  text-shadow: 3px 3px var(--red), 5px 5px var(--orange), 8px 8px var(--green), 11px 11px var(--purple), 14px 14px var(--background-color);
  margin: 0;
  font-size: 8rem;
}

html body section#welcome {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

html body section#welcome .welcome-wrapper {
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  display: flex;
}

html body section#welcome .welcome-wrapper .profile-img {
  height: 360px;
  width: 360px;
  filter: grayscale(50%);
  box-shadow: inset 0 1px 150px 10px var(--black), 0px 0px 30px 0px var(--black);
  background-image: url("profile_photo.f68ab86a.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 40%;
}

html body section#welcome .welcome-wrapper .profile-img:hover {
  background-image: url("goofy_profile_photo.1665243c.jpg");
}

html body section#welcome .welcome-wrapper:has(.profile-img:hover) .introduction .easter-egg {
  visibility: visible;
}

html body section#welcome .welcome-wrapper .introduction .easter-egg {
  visibility: hidden;
}

html body section#welcome .welcome-wrapper .introduction h1 {
  margin: 0;
  line-height: 2.7rem;
}

html body section#welcome .welcome-wrapper .introduction h1:nth-child(1) {
  padding-left: 0;
}

html body section#welcome .welcome-wrapper .introduction h1:nth-child(2) {
  padding-left: 2rem;
}

html body section#welcome .welcome-wrapper .introduction h1:nth-child(3) {
  padding-left: 4rem;
}

html body section#welcome .welcome-wrapper .introduction h1:nth-child(4) {
  padding-left: 6rem;
}

html body section#welcome .welcome-wrapper .introduction h1:nth-child(5) {
  padding-left: 8rem;
}

html body section#projects .projects-wrapper {
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

html body section#projects .projects-wrapper .image {
  z-index: -1;
  border-left: 5px solid var(--comment);
  background-image: url("projects-background.178b13f7.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: 1 / 2;
}

html body section#projects .projects-wrapper .content {
  flex-direction: column;
  grid-area: 1 / 1;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

html body section#projects .projects-wrapper .content .project-list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

html body section#projects .projects-wrapper .content .project-list .project {
  width: 300px;
  height: 170px;
  border-radius: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

html body section#projects .projects-wrapper .content .project-list .project.iris {
  background-image: url("irisloebbering.3ebc80f2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html body section#projects .projects-wrapper .content .project-list .project span {
  background-color: var(--selection-color-transparent);
  color: var(--text-color);
  border-radius: 15px;
  padding: 10px;
  font-size: 2rem;
  font-weight: 900;
}

html body section#about .about-wrapper {
  height: 100%;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

html body section#about .about-wrapper .image {
  z-index: -1;
  border-right: 5px solid var(--comment);
  background-image: url("about-background.ac644a77.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-column: 1;
}

html body section#about .about-wrapper .content {
  flex-direction: column;
  grid-column: 2;
  justify-content: center;
  align-items: center;
  padding: 30px;
  display: flex;
}

html body section#contact {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

html body section#contact .contact-wrapper {
  text-align: center;
}

html body section#contact .contact-wrapper .social {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  padding-top: 40px;
  display: flex;
}

html body section#contact .contact-wrapper .social .item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  display: flex;
}

html body section#contact .contact-wrapper .social .item a {
  color: #fff;
  font-style: normal;
}

html body section#contact .contact-wrapper .note {
  padding-top: 40px;
}

@media (max-width: 768px) {
  html body section {
    height: auto;
    box-shadow: none;
  }

  html body section#home, html body section#welcome, html body section#projects, html body section#contact {
    height: 100vh;
  }

  html body section#home h1 {
    font-size: 5rem;
  }

  html body section#welcome, html body section#welcome .welcome-wrapper {
    flex-direction: column;
  }

  html body section#welcome .welcome-wrapper .profile-img {
    width: 200px;
    height: 200px;
    box-shadow: inset 0 1px 50px 10px var(--black), 0 0 30px var(--black);
  }

  html body section#welcome .welcome-wrapper .introduction h1:nth-child(n) {
    padding: 0;
  }

  html body section#projects .projects-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  html body section#projects .projects-wrapper .image {
    min-height: 240px;
    border: none;
    grid-area: 1 / 1;
  }

  html body section#projects .projects-wrapper .content {
    grid-area: 2 / 1;
    justify-content: flex-start;
  }

  html body section#about .about-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  html body section#about .about-wrapper .image {
    min-height: 240px;
    border: none;
    grid-area: 1 / 1;
  }

  html body section#about .about-wrapper .content {
    grid-area: 2 / 1;
    justify-content: flex-start;
  }

  html body section#contact .contact-wrapper {
    padding: 20px;
  }

  html body section#contact .contact-wrapper .social {
    flex-wrap: wrap;
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.scale-up-center, html body nav.navbar .navbar__container .navbar__links .active {
  animation: scale-up-center .4s cubic-bezier(.39, .575, .565, 1) both;
}

:root {
  --background-color: #282a36;
  --text-color: #f8f8f2;
  --orange: #ffb86c;
  --red: #f55;
  --green: #50fa7b;
  --purple: #bd93f9;
  --selection-color: #44475a;
  --selection-color-transparent: #44475acc;
  --comment: #6272a4;
  --black: #000;
  --white: #fff;
}

html {
  scroll-behavior: smooth;
}

html ::selection {
  background-color: var(--selection-color);
}

html .no-scroll {
  overflow: hidden;
}

html body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  font-family: Lora, Georgia, serif;
  font-size: 1.2rem;
  font-weight: 400;
}

html body strong {
  font-weight: bold;
}

@media (max-width: 1200px) {
  html body {
    font-size: 1rem;
  }
}

/*# sourceMappingURL=index.9d4dcf83.css.map */
