@import './navbar.scss';
@import './sections.scss';
@import './animations.scss';
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap');

:root {
    // Drakula theme
    --background-color: #282a36;
    --text-color: #f8f8f2;
    --orange: #ffb86c;
    --red: #ff5555;
    --green: #50fa7b;
	--purple: #bd93f9;
    --selection-color: #44475a;
	--selection-color-transparent: rgba(68, 71, 90, 0.8);
    --comment: #6272a4;
	--black: #000;
	--white: #fff;
}

html {
    scroll-behavior: smooth;

    *::selection {
        background-color: var(--selection-color);
    }

	.no-scroll {
		overflow: hidden;
	}

    body {
        background-color: var(--background-color);
        color: var(--text-color);
        margin: 0;
        font-family: "Lora", "Georgia", serif;
        font-size: 1.2rem;
        font-weight: 400;

        strong {
            font-weight: bold;
        }
    }
}

@media (max-width: 1200px){
	html {
		body {
			font-size: 1.0rem;
		}
	}
}