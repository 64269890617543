html {
	body {
		nav.navbar {
			color: var(--text-color);
			position: fixed;
			top: 10px;
			width: 100%;
			z-index: 10;

			.navbar__container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 0 20px;

				a {
					color: var(--text-color);
					text-decoration: none;
				}

				.navbar__logo {
					img {
						height: 50px;
						width: 50px;
					}
				}

				.navbar__links {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
					gap: 15px;
					background-color: var(--selection-color);
					border-radius: 5px;
					padding: 0;
					font-weight: bold;

					.active {
						@extend .scale-up-center;
						background-color: var(--comment);
						border-radius: 5px;
					}

					a {
						padding: 15px;

						&:before {
							display: block;
							content: "";
							border-top: solid 3px transparent;
							transform: scaleX(0);
							transition: transform 250ms ease-in-out;
						}

						&:after {
							display: block;
							content: "";
							border-bottom: solid 3px var(--green);
							transform: scaleX(0);
							transition: transform 250ms ease-in-out;
						}

						&:hover:after {
							transform: scaleX(1);
						}
					}
				}

				.mobile__btn {
					display: none;

					svg {
						height: 25px;
						width: 25px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	html {
		body {
			nav.navbar {
				top: 0;
				background-color: var(--selection-color);

				.navbar__container {
					padding: 0 20px;
					justify-content: space-between;

					.navbar__logo {
						display: block;

						img {
							padding: 10px;
							height: 30px;
							width: 30px;
						}
					}

					.navbar__links {
						display: none;

						&.mobile {
							width: 100%;
							height: 100vh;
							background-color: var(--background-color);
							display: flex;
							position: absolute;
							top: 54px;
							left: 0;
							flex-direction: column;
							gap: 15px;
							align-items: center;
							justify-content: center;
							border-radius: 0;
						}
					}

					.mobile__btn {
						display: block;
						height: 30px;
						width: 30px;
					}
				}
			}
		}
	}
}
